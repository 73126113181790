import React from 'react'
import { padding, bg } from '../../utils'

import "./References.scss"

const References = ({ data }) => {
  const { references, topSpacing, bottomSpacing, hideTitle } = data


  return (
    <div style={padding(topSpacing, bottomSpacing)} class="referenceBlock">
      <div className="container">
        {!hideTitle && (
          <h3 className="text-mediumgray text-2xl">References</h3>
        )}
        <div className="references-list" dangerouslySetInnerHTML={{__html: references}} />
      </div>
    </div>
  )
}

export default References
