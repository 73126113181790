import React, { useState, useEffect } from 'react'
import SpaceNColor from '../SpaceNColor/SpaceNColor'
import { StyledLink } from '../Buttons/Button'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import 'react-tabs/style/react-tabs.css';
import './Publications.scss'

const TabInnards = ({year, publications}) => {
  const thisYearsPubs = publications.filter( pub => pub.year === year)
  return (
    <>
      {thisYearsPubs.map((pub, i) => (
        <div className="single-publication" key={i}>
          <div className="details">
            <h3 className="uppercase">{pub.month} {pub.year}&nbsp;&nbsp;<span className="font-normal">|</span>&nbsp;&nbsp;{pub.wherePublished}</h3>
            <p><a href={pub.url} target="_blank">{pub.title}</a></p>
            <p>{pub.publicationData}</p>
          </div>
          <div>
            <StyledLink to={pub.url} target="_blank">VISIT</StyledLink>
          </div>
        </div>
      ))}
    </>
  )
}

const Publications = ({ data }) => {
  // destructure props.data into user-friendly consts
  const {
    bottomSpacing,
    tintedBackground,
    topSpacing,
    publications
  } = data
  const [allYears, setAllYears] = useState(null)

  useEffect(() => {
    const years = [...new Set(publications.map(x => x.year))].sort( (a,b) => b-a )
    setAllYears(years)
  }, [])





  return (
    <SpaceNColor name='publications' tintedBackground={tintedBackground} bottomSpacing={bottomSpacing} topSpacing={topSpacing}>
      <div className="container">
        {allYears && (
          <Tabs>
            <TabList>
              {allYears.map( year => (
                <Tab key={year}>
                  {year}
                </Tab>
              ))}
            </TabList>
            {allYears.map( (year, i) => (
              <TabPanel key={i}>
                <TabInnards year={year} publications={publications} />
              </TabPanel>
            ))}
          </Tabs>
        )}
      </div>
    </SpaceNColor>
  )
}

export default Publications
