import React, { useEffect } from 'react'
import SpaceNColor from '../SpaceNColor/SpaceNColor'
import { StyledLink } from '../Buttons/Button'
import { useWindowSize, createLocalLink } from '../../utils/index'

import './StaticHero.scss'

const StaticHero = ({ data }) => {
  // destructure props.data into user-friendly consts
  const {
    bottomSpacing,
    tintedBackground,
    topSpacing,
    desktopImage,
    mobileImage,
    backgroundPosition,
    text,
    cta,
    heading,
  } = data

  const width = useWindowSize()
  const bgImage = width >= 768 ? desktopImage.sourceUrl : mobileImage.sourceUrl
  let blockClass = 'static-hero';

  blockClass += (backgroundPosition && (backgroundPosition.mobile || backgroundPosition.desktop)) ? ' ' + backgroundPosition.mobile + ' ' + backgroundPosition.desktop : '';

  return (
    <SpaceNColor name='static-hero' tintedBackground={tintedBackground} bottomSpacing={bottomSpacing} topSpacing={topSpacing}>
      <div style={{backgroundImage: `url(${bgImage})`}} className={blockClass}>
        <div className="container w-full flex">
          <div className="md:grid md:grid-cols-12 w-full items-center">
            <div className="md:col-span-5">
              {heading && <h1>{heading}</h1>}
              {text && <p className="text-white" dangerouslySetInnerHTML={{__html: text}} />}
              {cta && <StyledLink color="white" to={createLocalLink(cta.url)}>{cta.title}</StyledLink>}
            </div>
          </div>
        </div>
      </div>
    </SpaceNColor>
  )
}

export default StaticHero
