import React from 'react'
import SpaceNColor from '../SpaceNColor/SpaceNColor'

import './StackedIcons.scss'

const IconRow = ({icon}) => {


  return (
    <div className="flex items-center icon-row mt-0 mb-6 last:mb-0">
      <div className="icon">
        <img src={icon.icon.sourceUrl} alt={icon.icon.title} />
      </div>
      <div>
        <h3 className="uppercase m-0" dangerouslySetInnerHTML={{ __html: icon.description }}/>
      </div>
    </div>
  )
}

const StackedIcons = ({ data }) => {
  // destructure props.data into user-friendly consts
  const {
    bottomSpacing,
    tintedBackground,
    topSpacing,
    icons,
    heading
  } = data



  return (
    <SpaceNColor name='stacked-icons' tintedBackground={tintedBackground} bottomSpacing={bottomSpacing} topSpacing={topSpacing}>
      <div className="container grid md:grid-cols-2 gap-16 ">
        <div>
          <h1 className="stacked-heading" dangerouslySetInnerHTML={{__html: heading}}></h1>
        </div>
        <div>
          {icons.map( (icon, i) => (
            <IconRow key={i} icon={icon} />
          ))}
        </div>
      </div>
    </SpaceNColor>
  )
}

export default StackedIcons
