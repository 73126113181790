import React from 'react'
import GoogleMapReact from 'google-map-react';
import { ReactComponent as MapIcon} from '../../images/map-marker.svg'

import './Map.scss'

const Marker = () => {
  return(
    <div>
      <MapIcon />
    </div>
  )
}

const Map = () => {
  // bootstrapURLKeys={{ key: process.env.GATSBY_MAPS_KEY}}
  const mapOptions = {
    styles: require(`./mapstyle.json`)
  }

  return (
    <div id="map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_MAPS_KEY}}
        defaultCenter={{
          lat: 42.3633654,
          lng: -71.0938125
        }}
        defaultZoom={11}
        options={mapOptions}
      >
        <Marker
          lat={42.3633654}
          lng={-71.0938125}
          text="My Marker"
        />
      </GoogleMapReact>
    </div>
  )
}

export default Map
