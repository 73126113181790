import React from 'react'
import SpaceNColor from '../SpaceNColor/SpaceNColor'
import './ImpactTiles.scss'
import { useWindowSize } from '../../utils'
import ImpactTilesDesktop from './ImpactTilesDesktop'
import ImpactTilesMobile from './ImpactTilesMobile'

const ImpactTiles = ({ data }) => {
    const {
      bottomSpacing,
      tintedBackground,
      topSpacing,
      tiles,
    } = data
  const width = useWindowSize()


  return (
    <SpaceNColor name='impact-tiles' tintedBackground={tintedBackground} bottomSpacing={bottomSpacing} topSpacing={topSpacing}>
      {width <= 768 ? (
        <ImpactTilesMobile tiles={tiles} />
      ) : (
        <ImpactTilesDesktop tiles={tiles} />
      )}
    </SpaceNColor>
  )
}

export default ImpactTiles
