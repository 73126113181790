import React from 'react'
import SpaceNColor from '../SpaceNColor/SpaceNColor'

import './XenoChart.scss'

const XenoChart = ({ data }) => {
  /*
  const {
    bottomSpacing,
    tintedBackground,
    topSpacing
  } = data
  */


  return (

      <div className="container">

      </div>

  )
}

export default XenoChart

/*
  <div className="xeno-chart">
          <div className="step">
            <div className="step-innards">
              <div className="step__icon">
                <img src="https://www.fillmurray.com/200/200" alt=""/>
              </div>
              <div className="step__description">
                <h3>17th CENTURY</h3>
                <p>First expirements in xenotransplantation.<sup>2</sup></p>
              </div>
            </div>
          </div>
        </div>

*/
