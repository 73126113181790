import React from 'react'
import SpaceNColor from '../SpaceNColor/SpaceNColor'

import './TextColumns.scss'

const TextColumns = ({ data }) => {
  // destructure props.data into user-friendly consts
  const {
    bottomSpacing,
    tintedBackground,
    topSpacing,
    block: cols,
    columns
  } = data



  return (
    <SpaceNColor name='text-columns' tintedBackground={tintedBackground} bottomSpacing={bottomSpacing} topSpacing={topSpacing}>
      <div className="container">

        {columns == 3 && (
          <div className={`grid gap-x-8 gap-y-12 md:grid-cols-3`}>
          {cols.map( (col, i) => (
              <div key={i}>
                <h3 className="font-black">{col.heading}</h3>
                <div dangerouslySetInnerHTML={{__html: col.text}} />
              </div>
            ))}
          </div>
        )}

        {columns == 4 && (
          <div className={`grid gap-x-8 gap-y-12 md:grid-cols-4`}>
          {cols.map( (col, i) => (
              <div key={i}>
                <h3 className="font-black uppercase">{col.heading}</h3>
                <div dangerouslySetInnerHTML={{__html: col.text}} />
              </div>
            ))}
          </div>
        )}

        {columns == 2 && (
          <div className={`grid gap-x-8 gap-y-12 md:grid-cols-2`}>
          {cols.map( (col, i) => (
              <div key={i}>
                <h3 className="font-black">{col.heading}</h3>
                <div dangerouslySetInnerHTML={{__html: col.text}} />
              </div>
            ))}
          </div>
        )}

      </div>
    </SpaceNColor>
  )
}

export default TextColumns
