import React, { useState } from 'react';
import { Fade } from 'react-slideshow-image';
import { motion } from 'framer-motion'
import 'react-slideshow-image/dist/styles.css'
// https://react-slideshow.herokuapp.com/fade-effect

import './Hero.scss';
import arrow from '../../images/read-more-arrow.svg'

const Panel = ({data, current}) => {
  const { backgroundImage, heading, backgroundPosition } = data
  const style = { backgroundImage: `url(${backgroundImage.sourceUrl})` }
  let blockClass = 'each-fade';
  blockClass += (backgroundPosition && (backgroundPosition.mobile || backgroundPosition.desktop)) ? ' ' + backgroundPosition.mobile + ' ' + backgroundPosition.desktop : ''; 

  return (
    <div className={blockClass} style={style}>
      <div className="container">
        <div className="slide-grid">
          <div className="heading">
            {current && (
              <motion.div
                className="opacity-0"
                animate={{
                  x: [-100, 0, 0, -100],
                  opacity: [0,1,1,0],
                }}
                transition={{
                  duration: 7.5,
                  times: [0, 0.15, 0.85, 1]
                }}
              >
                {heading && <h1 className="text-white">{heading}</h1>}
              </motion.div>
            )}


          </div>
          <div className="read-more">
            <div className="arrow" style={{backgroundImage: `url(${arrow})`}}></div>
          </div>
        </div>

      </div>
    </div>
  )
}

const Hero = (props) => {
  const [current, setCurrent] = useState(0)
  const { panels } = props.data
  const properties = {
    arrows: false,
    pauseOnHover: false,
    duration: 7500,
    transitionDuration: 250,
    onChange: (previous, next) => setCurrent(next)
  }

  const squeeze = !props.fullHeight ? 'squeeze' : ''


  return (
    <div className="bg-indigo">
      {panels && (
        <Fade className={`fade-slider ${squeeze}`} {...properties}>
          {panels.map( (panel, i) => (
            <Panel data={panel} key={i} current={current === i && true} />
          ))}
        </Fade>
      )}
    </div>
  )
}

export default Hero
