import React, { useEffect, useRef } from 'react'
import { ArrowOnlyButton, StyledLink } from '../Buttons/Button'
import { motion } from 'framer-motion'
import { ReactComponent as Close} from '../../images/close-icon-white.svg'
import ReactPlayer from 'react-player'

const HybridVideoPanel = ({innards}) => {
  const {
    data: story,
    handleOpen,
    handleClose,
    isOpen
  } = innards
  const blue = 'linear-gradient(rgba(35, 53, 132, 0.4), rgba(35, 53, 132, 0.4))'
  const variants = {
    vidAppear: {opacity: 1},
    vidDisappear: {opacity: 0}
  }



  return (
    <div className="hybrid-panel-video">
       {isOpen && (
        <div className="hybrid-panel-video__details">
          <button onClick={handleClose}><Close /></button>
          <motion.div
            className="relative h-full"
            initial="vidDisappear"
            animate={isOpen ? 'vidAppear' : 'vidDisappear'}
            variants={variants}
            transition={{delay: 0.5, duration: 0.5}}
          >
            <ReactPlayer
              playing={isOpen}
              className="featured-video"
              url={story.video}
              controls
              width="100%"
              height="100%"
            />

          </motion.div>
        </div>
      )}

      <div
        className="hybrid-panel-video__image"
        style={{backgroundImage: `${blue}, url(${story.image.sourceUrl})`}}
      >
        <div className="image-content">
          <h2 className="text-white md:text-2xl lg:text-3xl">{story.title}</h2>
          <ArrowOnlyButton color="white" onClick={handleOpen} />
        </div>
      </div>
    </div>
  )
}

export default HybridVideoPanel
