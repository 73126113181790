import React, { useState } from 'react'
import SpaceNColor from '../SpaceNColor/SpaceNColor'
import VideoPanel from '../VideoPanel/VideoPanel'
import VideoPanelMobile from '../VideoPanel/VideoPanelMobile'
import { createLocalLink, useWindowSize } from '../../utils'
import { motion } from 'framer-motion'

import './VideoSlider.scss'
import { StyledLink } from '../Buttons/Button'




const VideosNarrow = ({tiles, intro}) => {
  return (
    <div>
      <div className="container">
        <div className="col-start-2 py-8" dangerouslySetInnerHTML={{__html: intro}} />
      </div>
      {tiles.map((tile, i) => (
        <VideoPanelMobile
          key={i}
          tile={tile}
        />
      ))}
    </div>
  )
}


const VideosIntro = ({ intro, cta }) => {
  return (
    <div className="intro">
      <div className="col-start-2 py-8 pr-12">
        <div dangerouslySetInnerHTML={{__html: intro}} />
        {cta && <StyledLink arrow="right" to={createLocalLink(cta.url)}>{cta.title}</StyledLink>}
      </div>
    </div>
  )
}


const VideosWide = ({tiles, intro, cta}) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ translateX, setTranslateX ] = useState(0)
  const [ activePanel, setActivePanel ] = useState(null)

  const handleClick = (i) => {
    activePanel === i
      ? setActivePanel(null)
      : setActivePanel(i)
    setIsOpen(!isOpen)

    translateX > 0
      ? setTranslateX(0)
      : setTranslateX(i * 25 + 50)
  }

  return (

    <motion.div
      animate={{ transform: `translateX(-${translateX}vw)`}}
      transition={{duration: 0.5}}
      className={`vid-desk-wrap ${isOpen ? 'open' : ''}`}
    >
      <VideosIntro intro={intro} cta={cta} />
      {tiles.map((tile, i) => (
        <VideoPanel
          key={i}
          tile={tile}
          isActive={activePanel === i}
          handleClick={() => handleClick(i)}
        />
      ))}
    </motion.div>
  )
}



const VideoSlider = ({ data }) => {
  const {
    bottomSpacing,
    topSpacing,
    intro,
    cta,
    videoTiles,
  } = data
  const width = useWindowSize()

  return (
    <SpaceNColor name='video-slider' bottomSpacing={bottomSpacing} topSpacing={topSpacing}>
      <div className="vs-wrapper">
        {width >= 768 ? (
          <VideosWide tiles={videoTiles} intro={intro} cta={cta} />
        ) : (
          <VideosNarrow tiles={videoTiles} intro={intro} cta={cta} />
        )}
      </div>
    </SpaceNColor>
  )
}

export default VideoSlider
