import React from 'react'
import SpaceNColor from '../SpaceNColor/SpaceNColor'

import './TriColumns.scss'

const TriColumns = ({ data }) => {
  // destructure props.data into user-friendly consts
  const {
    bottomSpacing,
    tintedBackground,
    topSpacing,
    block: cols
  } = data


  return (
    <SpaceNColor name='tri-columns' tintedBackground={tintedBackground} bottomSpacing={bottomSpacing} topSpacing={topSpacing}>
      <div className="container">
        <div className="grid grid-cols-3 gap-x-8 gap-y-12">
          {cols.map( (col, i) => (
            <div key={i}>
              <h3 className="font-black">{col.heading}</h3>
              <div dangerouslySetInnerHTML={{__html: col.text}} />
            </div>
          ))}
        </div>
      </div>
    </SpaceNColor>
  )
}

export default TriColumns
