import React, { useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { padding, bg } from '../../utils'
import AOS from "aos";
import _ from 'lodash';
import "aos/dist/aos.css";

import './LeadershipBlock.scss'

const TeamMemberTile = ({data, idx}) => {

    const name = data?.title || "";
    const uri = data?.uri || "";
    console.log(data);

    let headshot, title;

    if (data?.leadership) {
      headshot = data?.leadership?.headshot;
      title = data?.leadership?.title;
    }

    useEffect(() => {
      AOS.init({
        duration : 400,
        once: true,
      });
    });

    let delay = 75 + ((idx+1) * 50);

    if (!headshot || !name || !title || !uri) {
      return null;
    }

    return (
        <Link to={uri} data-aos="fade-in" className="small-bio no-underline hover:text-text">
          <div className="small-bio__image">
            {headshot && (
              <img src={headshot.sourceUrl} alt={headshot.title} className="headshot-small" />
            )}
          </div>
          <h3 className="uppercase font-black mb-2">{name}</h3>
          <p className="mt-0">{title}</p>
        </Link>
    )
  }



const LeadershipBlock = ({ data }) => {
  // destructure props.data into user-friendly consts
  const {
    bottomSpacing,
    description,
    heading,
    tintedBackground,
    topSpacing,
    roster
  } = data

  let anchorName = 'section-' + _.camelCase(heading);

  return (
    <div id={anchorName} className={`leadership-block ${bg(tintedBackground)}`} style={padding(topSpacing, bottomSpacing)}>
      <div className="container">
        <div className="grid md:grid-cols-12">
          <div className="col-span-9">
            <h1>{heading}</h1>
          </div>
          {description && <div className="col-span-9 mb-8" dangerouslySetInnerHTML={{__html: description}}></div>}
        </div>
        <div className="grid gap-x-8 gap-y-12 sm:grid-cols-2 md:grid-cols-4 ">
          {roster.map( (single, i) => (
            <TeamMemberTile idx={i} data={single.person} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default LeadershipBlock
