import React from 'react'
import { padding, bg } from '../../utils'

const SpaceNColor = ({
  name = '',
  children,
  topSpacing = 32,
  bottomSpacing = 32,
  tintedBackground = null,
}) => {


  return (
    <div className={`${name} ${bg(tintedBackground)}`} style={padding(topSpacing, bottomSpacing)}>
      {children}
    </div>
  )
}

export default SpaceNColor
