import React, { useEffect } from 'react'
import { ArrowOnlyButton, StyledLink } from '../Buttons/Button'
import { ReactComponent as Close} from '../../images/close-icon-white.svg'
import { createLocalLink } from '../../utils/index'

const HybridStoryPanel = ({innards}) => {

  const {
    data: story,
    handleOpen,
    handleClose,
    isOpen
  } = innards
  const blue = 'linear-gradient(rgba(35, 53, 132, 0.4), rgba(35, 53, 132, 0.4))'

  return (
    <div className="hybrid-panel-story">
      <div
        className="hybrid-panel-story__image"
        style={{backgroundImage: `${blue}, url(${story.image.sourceUrl})`}}
      >
        <div className="image-content">
          <h2 className="text-white md:text-2xl lg:text-3xl">{story.title}</h2>
          <ArrowOnlyButton color="white" onClick={handleOpen} />
        </div>
      </div>
      {isOpen && (
        <div className="hybrid-panel-story__details">
          <header className="flex justify-between items-start">
            <h2 className="text-white">{story.title}</h2>
            <button onClick={handleClose}><Close /></button>
          </header>
          <figure>
            <blockquote>
              <p className="text-3xl text-white font-light italic mb-" style={{textIndent: '-8px'}}>{story.quote}</p>
              <figcaption className="text-white font-bold text-xl mt-6">{story.quotee}</figcaption>
            </blockquote>
          </figure>
          <div>
            <StyledLink color="white" to={createLocalLink(story.cta.url)}>{story.cta.title}</StyledLink>
          </div>
        </div>
      )}
    </div>
  )
}

export default HybridStoryPanel
