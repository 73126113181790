import 'intersection-observer'
import React from 'react'
import { padding, bg } from '../../utils'
import InView from 'react-intersection-observer';

const Counter = ({number, idx}) => { 
  const changeNumber = (inView, entry) => {
    if (inView) {
      let count = entry.target.dataset.count;
      let current = 0;
      let stepCount = (count > 30) ? 30 : count;
      let step = Math.round(count / stepCount);
      let interval = setInterval(()=> {
        current = (current + step > count) ? count : current+step;
        let display = current.toLocaleString();
        if (display == 100000) {
          //IDK why toLocaleString isn't working on 100K only, but I don't have time to figure it out
          display = '100,000';
        }
        entry.target.innerHTML = display;
        if (current == count) {
          clearInterval(interval);
        }
      }, 30);
    }
  }

  number = number.replace(',', '');

  return (
    <InView as="span" triggerOnce={true} onChange={(inView, entry) => changeNumber(inView, entry) } data-count={number} class="counterAnimation text-red">0</InView>  
  )
}

const Stat = ({data, idx}) => {
  const {
    description,
    statNumber: number,
    statSubject: subject
  } = data

  return (
    <div>
      <h2 className="font-bold uppercase text-lg text-text mb-4">
        <Counter idx={idx} number={number} />
        &nbsp;<span dangerouslySetInnerHTML={{__html: subject}}></span>
      </h2>
      <div dangerouslySetInnerHTML={{__html: description}}></div>
    </div>
  )
}

const QuickStats = ({ data }) => {
  const {
    tintedBackground,
    topSpacing,
    bottomSpacing,
    stats
  } = data

  return (
    <div className={`quick-stats ${bg(tintedBackground)}`} style={padding(topSpacing, bottomSpacing)}>
      <div className="container">
        <div className="grid gap-8 gap-x-8 gap-y-12 md:grid-cols-2">
          {stats && stats.map((stat, i) => (
            <Stat data={stat} idx={i} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default QuickStats
