import React, { useState, useRef, useEffect } from 'react'
import { motion } from 'framer-motion'
import { StyledLink } from '../Buttons/Button'
import { ReactComponent as Close} from '../../images/close-icon-white.svg'
import ReactPlayer from 'react-player'


import './VideoPanel.scss'

const MobileVideo = ({tile, handleClose}) => {

  return (
    <div className="flex justify-center flex-col absolute h-full w-full top-0 bg-indigo">
      <header className="flex justify-end p-6" style={{marginTop: "-40px"}}>
        <Close onClick={handleClose} />
      </header>
      <ReactPlayer
        url={tile.video}
        controls
        width="100%"
      />
    </div>
  )
}

const VideoPanelMobile = ({tile}) => {
  const blue = 'linear-gradient(rgba(35, 53, 132, 0.4), rgba(35, 53, 132, 0.4))'
  const [active, setActive] = useState(false)

  const handleClick = () => {
    setActive(!active)
  }

   return (
    <div className='video-panel mobile'>
      <div
        className="front"
        style={{backgroundImage: `${blue}, url(${tile.backgroundImage.sourceUrl})`}}
      >
        <div>
          <h2 className="text-white">{tile.heading}</h2>
          <button className="fancy text-white" onClick={handleClick}>PLAY</button>
        </div>
      </div>
      {active && (
        <MobileVideo tile={tile} handleClose={handleClick} />
      )}
    </div>
  )
}

export default VideoPanelMobile
