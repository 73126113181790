import React, { useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import { ArrowOnlyButton } from '../Buttons/Button'

import './VideoPanel.scss'

const VideoPanel = ({tile, handleClick, isActive}) => {
  const videoVariants = {
    open: {
      left: 0
    },
    closed: {
      left: '100vw'
    }
  }

  const variants = {
    open: { width: '75vw'},
    closed: { width: '0vw'},
    vidAppear: {opacity: 1},
    vidDisappear: {opacity: 0}
  }
  const blue = 'linear-gradient(rgba(35, 53, 132, 0.4), rgba(35, 53, 132, 0.4))'
  const vidRef = useRef(null);

  useEffect(() => {
    if (isActive) {
      vidRef.current.play()
    }
  }, [isActive])



   return (
    <div className='video-panel'>
      <motion.div
        className="the-video overflow-hidden"
        animate={isActive ? 'open' : 'closed'}
        variants={variants}
        transition={{ duration: 0.5}}
      >
        {isActive && (
          <motion.div
            className="opacity-0"
            animate={isActive ? 'vidAppear' : 'vidDisappear'}
            variants={variants}
            transition={{delay: 0.5, duration: 0.5}}
          >
            <video ref={vidRef} controls className="featured-video" src={tile.video}></video>
          </motion.div>
        )}
      </motion.div>
      <div
        className="front"
        style={{backgroundImage: `${blue}, url(${tile.backgroundImage.sourceUrl})`}}
      >
        <div className={`${isActive ? 'rotate' : ''}`}>
          <h2 className="text-white">{tile.heading}</h2>
          <ArrowOnlyButton direction="left" color="white" onClick={handleClick} />
        </div>
      </div>
    </div>
  )
}

export default VideoPanel
