import React, { useEffect } from 'react'
import { padding, bg } from '../../utils'
import AOS from "aos";
import "aos/dist/aos.css";
import './IconBlocks.scss'

const IconBlock = ({block, idx}) => {
  const { heading, description, icon } = block

  useEffect(() => {
    AOS.init({
      duration : 750,
      once: true
    });
  }, []);

  let offset = (idx+1) * 150;
  let delay = (idx+1) * 100;

  return (
    <div className="icon-block" data-aos-delay={delay} data-aos="fade-up" data-aos-offset={offset}>
      <img
        src={icon.sourceUrl} alt={icon.title}
      />
      <h3 className="uppercase">{heading}</h3>
      <div dangerouslySetInnerHTML={{__html: description}}></div>

    </div>
  )
}

const IconBlocks = ({ data }) => {
  const {
    bottomSpacing,
    tintedBackground,
    topSpacing,
    iconBlocks
  } = data

  let gridClass = 'ib-grid count-' + iconBlocks.length;

  return (
    <div className={`icon-blocks${bg(tintedBackground)}`} style={padding(topSpacing, bottomSpacing)}>
      <div className="container">
        <div className={gridClass}>
          {iconBlocks.map( (block, i) => (
            <IconBlock idx={i} block={block} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default IconBlocks
