import React from 'react'
import { motion } from 'framer-motion'
import { createLocalLink } from '../../utils/index'

import { ReactComponent as Close} from '../../images/close-icon-white.svg'
import { ArrowOnlyButton, StyledLink } from '../Buttons/Button'

import "./PromoPanel.scss"


const PromoPanel = ({story, isOpen, onBtnClick, handleClose}) => {
  const {
    cta,
    quote,
    quotee,
    title,
    type,
    video
  } = story
  const blue = 'linear-gradient(rgba(35, 53, 132, 0.4), rgba(35, 53, 132, 0.4))'

  const variants = {
    open: {
      x: '55vw'
    },
    closed: {
      x: 0,
    },
  }


  return (
    <div className={`promo-panel ${isOpen ? 'active' : ''}`}>
      <div className="panel-photo relative z-10 overflow-hidden" style={{backgroundImage: `${blue}, url(${story.image.sourceUrl})`}}>
        <div className="photo-container">

          <motion.div
            animate={isOpen ? 'open' : 'closed'}
            variants={variants}
            transition={{
              duration: 0.4,
              type: 'tween',
              ease: 'easeInOut',
              delay: 0.3,
            }}
          >
            <h2 className="text-white">{story.title}</h2>
            <ArrowOnlyButton
              color="white"
              onClick={onBtnClick}
            />
          </motion.div>
        </div>

      </div>
      {isOpen && (
        <motion.div
          className='panel-description px-16 md:px-8 lg:px-16 py-8 flex flex-col justify-between relative z-0'
          initial={{x: '-100%'}}
          animate={{ x: 0 }}
          transition={{
            duration: 0.5,
            type: 'tween',
            ease: 'easeInOut',
            delay: 0.3,
          }}
        >
          <header className="flex justify-between items-start">
            <h2 className="text-white">{title}</h2>
            <button onClick={handleClose}><Close /></button>
          </header>
          <figure>
            <blockquote>
              <p className="text-3xl text-white font-light italic mb-" style={{textIndent: '-8px'}}>{quote}</p>
              <figcaption className="text-white font-bold text-xl mt-6">{quotee}</figcaption>
            </blockquote>
          </figure>
          <div>
            <StyledLink color="white" to={createLocalLink(cta.url)}>{cta.title}</StyledLink>
          </div>

        </motion.div>
      )}
    </div>
  )
}

export default PromoPanel
