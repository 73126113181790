import React from 'react'
import './Text.scss'
import SpaceNColor from '../SpaceNColor/SpaceNColor'

const Heading = ({ data }) => {
  const { tagLevel, heading } = data
  const Tag = tagLevel


  return <Tag className="heading mb-0">{ heading }</Tag>
}

const Text = ({ data }) => {
  const {
    bottomSpacing,
    topSpacing,
    content,
    tintedBackground
  } = data

  const padding = { padding: `${topSpacing}px 0 ${bottomSpacing}px 0` }
  const bgColor = tintedBackground ? 'bg-lightblue' : 'bg-white'
  const contentType = content[0].fieldGroupName.split('_').pop()

  return (
    <SpaceNColor name='text' tintedBackground={tintedBackground} bottomSpacing={bottomSpacing} topSpacing={topSpacing}>
      <div className="container">
        {contentType === 'Heading' && (
          <Heading data={content[0]} />
        )}
      </div>
    </SpaceNColor>
  )
}

export default Text
