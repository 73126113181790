import React, { useState } from 'react'
import { ArrowOnlyButton, StyledLink } from '../Buttons/Button'
import { ReactComponent as Close} from '../../images/close-icon-white.svg'
import './PromoPanelMobile.scss'

const PromoPanelMobile = ({ story }) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="mobile-panel">
      <div className="front">
        <div className="panel" style={{backgroundImage: `url(${story.image.sourceUrl})`}}>
          <div className="inner">
            <div>
              <h2 className="text-white">{story.title}</h2>
              <ArrowOnlyButton color="white" onClick={handleClick} />
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="back" onClick={handleClick}>
          <div className="container py-6 flex flex-col justify-between h-full">
            <div>
              <header className='flex justify-between'>
                <h2 className="text-white mr-6">{story.title}</h2>
                <div>
                  <Close />
                </div>
              </header>

              <blockquote>
                <p className="text-3xl text-white font-medium italic">{story.quote}</p>
                <figcaption className="text-white font-bold text-xl mt-4">{story.quotee}</figcaption>
              </blockquote>
            </div>
            <div>
              <StyledLink to={story.cta.target} color="white">READ</StyledLink>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PromoPanelMobile
