import React, { useState, useEffect } from 'react'
import SpaceNColor from '../SpaceNColor/SpaceNColor'
import HybridStoryPanel from './HybridStoryPanel'
import HybridVideoPanel from './HybridVideoPanel'
import { useWindowSize } from '../../utils'
import PromoPanelMobile from '../PromoPanelMobile/PromoPanelMobile'
import HybridVideoPanelMobile from './HybridVideoPanelMobile'

import './HybridPanels.scss'

const HybridPanelMobile = ({stories}) => {

  return (
    <div>
      {stories.map((story, i) => (
        <div key={i}>
          {story.type === 'video' ? (
            <HybridVideoPanelMobile key={i} story={story} />
          ):(
            <PromoPanelMobile key={i} story={story} />
          )}
        </div>
      ))}
    </div>
  )
}

const HybridPanelDesktop = ({...props}) => {
  const { type } = props.data

  return (
    <div className={`hybrid-panel ${props.isOpen ? 'open' : 'closed'}`}>
      {type === 'story' ? (
        <HybridStoryPanel innards={props} />
      ):(
        <HybridVideoPanel innards={props} />
      )}
    </div>
  )
}

const HybridPanels = ({ data }) => {
  // destructure props.data into user-friendly consts
  const {
    bottomSpacing,
    tintedBackground,
    topSpacing,
    story
  } = data
  const [ translateX, setTranslateX ] = useState(0)
  const [ expanded, setExpanded ] = useState(false);
  const [ activePanel, setActivePanel ] = useState(null)

  const width = useWindowSize()

  const handleOpen = (i) => {
    setExpanded(true)
    activePanel ? setActivePanel(null) : setActivePanel(i)
    setTranslateX(`-${i * 25}vw`)
  }

  const handleClose = () => {
    setExpanded(false)
    setTranslateX(0)
    setActivePanel(null)
  }


  return (
    <SpaceNColor name='hybrid-panels-section' tintedBackground={tintedBackground} bottomSpacing={bottomSpacing} topSpacing={topSpacing}>
      {width >= 768 ? (
        <div className="hybrid-panels-wrap">
          <div className="hybrid-panels" style={{transform: `translateX(${translateX})`}}>
            {story.map((panel, i) =>(
              <HybridPanelDesktop
                key={i}
                data={panel}
                handleOpen={() => handleOpen(i)}
                handleClose={handleClose}
                isOpen={activePanel === i}
              />
            ))}
          </div>
        </div>
      ) : (
        <HybridPanelMobile
          stories={story}
        />
      )}
    </SpaceNColor>
  )
}

export default HybridPanels
