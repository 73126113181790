import React from 'react'
import SpaceNColor from '../SpaceNColor/SpaceNColor'

import './VideoHero.scss'

const VideoHero = ({ data }) => {
  const { videoUrl } = data

  return (
    <div className="video-hero" dangerouslySetInnerHTML={{__html: `
      <video autoplay preload loop muted playsinline>
        <source src=${videoUrl} />
      </video>
      `}} />
  )
}

export default VideoHero
