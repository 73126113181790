import React, { useState, useEffect } from 'react'
import SpaceNColor from '../SpaceNColor/SpaceNColor'
import { StyledLink } from '../Buttons/Button'
import ResponsiveFigure from '../ResponsiveFigure/ResponsiveFigure'

import './Openings.scss'

const SingleJob = ({job}) => {
  return (
      <div key={job.id} className="job my-12">
        <h3 className="uppercase">{job.text}</h3>
        <p>Location: {job.categories.location}</p>
        <StyledLink arrow="right" target="_blank" to={`${job.hostedUrl}`}>VIEW</StyledLink>
      </div>
  )
}

const Openings = ({ data }) => {
  // destructure props.data into user-friendly consts
  const {
    bottomSpacing,
    tintedBackground,
    topSpacing,
    caption,
    introText,
    figure,
    text
  } = data

  const [jobs, setJobs] = useState(null)
  const jobsUrl = `https://api.lever.co/v0/postings/egenesisbio?mode=json`
  const key = process.env.GATSBY_LEVER_KEY


  useEffect(() => {
    fetch(`${jobsUrl}&key=${key}`)
      .then(res => res.json())
      .then(result => {
        //const openJobs = result.filter( (job) => { return job.status === 'Open'});
        setJobs(result)
      })
      .catch((error) => {
        console.log(`Error: ${error}`)
      })
  }, [])


  return (
    <SpaceNColor name='openings' tintedBackground={tintedBackground} bottomSpacing={bottomSpacing} topSpacing={topSpacing}>
      <div className="container">
        <div className="jobs-grid grid md:grid-cols-2 gap-8">
          <div>
            <div dangerouslySetInnerHTML={{__html: introText}}></div>
            {jobs && jobs.length > 1 && jobs.map((job) => (
              <SingleJob job={job} key={job.id} />
            ))}
            {!jobs || jobs.length == 0 && 
            <div class="mt-10">
              <p>We currently do not have any open positions, but we welcome inquiries from qualified applicants at <a href="mailto:careers@egenesisbio.com">careers@egenesisbio.com</a>.</p>
            </div>
            }
          </div>
          <div>
            {figure && <ResponsiveFigure mobile={figure} desktop={figure} caption={caption} /> }
            <div dangerouslySetInnerHTML={{__html: text}}></div>
          </div>
        </div>
      </div>
    </SpaceNColor>
  )
}

export default Openings
