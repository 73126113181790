import React from 'react'
import SpaceNColor from '../SpaceNColor/SpaceNColor'
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { useStaticQuery, graphql, Link } from 'gatsby'
import { StyledLink } from '../Buttons/Button'
import PRPreview from '../PRPreview/PRPreview'

import './PRAndTwitter.scss'



const PRAndTwitter = ({ data }) => {
  // destructure props.data into user-friendly consts
  const {
    bottomSpacing,
    tintedBackground,
    topSpacing,
    tweets
  } = data
  const prData = useStaticQuery(graphql`
    query prData {
      allWpPressrelease(sort: {fields: date, order: DESC}, limit: 5) {
        nodes {
          date(formatString: "MMMM DD, YYYY")
          id
          title
          content
          uri
        }
      }
    }
  `)

  const pressReleases = prData.allWpPressrelease.nodes
  return (
    <SpaceNColor
      name="pr-twitter"
      tintedBackground={tintedBackground}
      bottomSpacing={bottomSpacing}
      topSpacing={topSpacing}
    >
      <div className="container">
        <div className="grid md:grid-cols-2 gap-8">
          <div>
            <h1>Press releases</h1>
            <ul>

              {pressReleases.map((pr) => (
                <PRPreview key={pr.id} pr={pr} />
              ))}
            </ul>
            <StyledLink to="/press-releases" arrow="right">
              VIEW ALL
            </StyledLink>
          </div>
          <div className="col-span-1">
            <h1>Twitter feed</h1>
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName="eGenesisBio"
              options={{ height: 800 }}
            />
          </div>
        </div>
      </div>
    </SpaceNColor>
  );
}

export default PRAndTwitter
