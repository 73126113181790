import React from 'react'
import SpaceNColor from '../SpaceNColor/SpaceNColor'
import Map from '../Map/Map'

import './Contact.scss'

const ContactForm = ({ data }) => {
  return (
      <form
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="contact"
        className="grid gap-8"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />

        <div className="form-row grid grid-cols-2 gap-8">
          <div className="form-field">
            <label htmlFor="firstname">First Name</label>
            <input required  type="text" name="firstname" id="firstname" placeholder="First Name*" />
          </div>
          <div className="form-field">
            <label htmlFor="lastname">Last Name</label>
            <input required  type="text" name="lastname" id="lastname" placeholder="Last Name*" />
          </div>
        </div>

        <div className="form-row">
          <div className="form-field">
            <label htmlFor="email">Email</label>
            <input required  type="email" name="email" id="email" placeholder="Email*" />
          </div>
        </div>

        <div className="form-row">
          <div className="form-field">
            <label htmlFor="phone">Phone</label>
            <input required  type="tel" name="phone" id="phone" placeholder="Phone*" />
          </div>
        </div>

        <div className="form-row">
          <div className="form-field">
            <label htmlFor="message">Message</label>
            <textarea required name="message" id="message" rows="10" placeholder="Comment*"></textarea>
          </div>
        </div>

        <div>
          <button className="fancy" type="submit">SUBMIT</button>
        </div>

      </form>
  )
}

const Contact = ({ data }) => {
  // destructure props.data into user-friendly consts
  const {
    bottomSpacing,
    tintedBackground,
    topSpacing,
    contactLocations: cols
  } = data


  return (
    <SpaceNColor name='contact' tintedBackground={tintedBackground} bottomSpacing={bottomSpacing} topSpacing={topSpacing}>
      <div className="container">
        <div className="grid gap-8 md:grid-cols-2">
          <div>
            <h1>Contact eGenesis</h1>
            <div id="locations">
              {cols.map( (col, i) => (
                <div key={i}>
                  <h3 className="font-black">{col.header}</h3>
                  <div dangerouslySetInnerHTML={{__html: col.address}} />
                </div>
              ))}
            </div>
            <ContactForm />
          </div>
          <Map/>
        </div>
      </div>
    </SpaceNColor>
  )
}

export default Contact
