import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/Layout'
import { last } from 'lodash-es'
import Seo from 'gatsby-plugin-wpgraphql-seo';

import Article from '../components/Article/Article'
import Contact from '../components/Contact/Contact'
import Hero from '../components/Hero/Hero'
import IconBlocks from '../components/IconBlocks/IconBlocks'
import ImpactTiles from '../components/ImpactTiles/ImpactTiles'
import LeadershipBlock from '../components/LeadershipBlock/LeadershipBlock'
import MediaCoverage from '../components/MediaCoverage/MediaCoverage'
import Openings from '../components/Openings/Openings'
import QuickStats from '../components/QuickStats/QuickStats'
import PatientStories from '../components/PatientStories/PatientStories'
import Pipeline from '../components/Pipeline/Pipeline'
import PRAndTwitter from '../components/PRAndTwitter/PRAndTwitter'
import HybridPanels from '../components/HybridPanels/HybridPanels'
import PromotedContentHalf from '../components/PromotedContentHalf/PromotedContentHalf'
import Publications from '../components/Publications/Publications'
import References from '../components/References/References'
import StackedIcons from '../components/StackedIcons/StackedIcons'
import StaticHero from '../components/StaticHero/StaticHero'
import Text from '../components/Text/Text'
import TextColumns from '../components/TextColumns/TextColumns'
import TriColumns from '../components/TriColumns/TriColumns'
import VideoHero from '../components/VideoHero/VideoHero'
import VideoSlider from '../components/VideoSlider/VideoSlider'
import XenoChart from '../components/XenoChart/XenoChart'


const switcheroo = (component, i, isFrontPage) => {
  const compName = last(component.fieldGroupName.split('_'))

  switch(compName) {
    case 'Article' : return <Article key={i} data={component} />
    case 'Contact' : return <Contact key={i} data={component} />
    case 'Hero' : return <Hero key={i} data={component} fullHeight={isFrontPage} />
    case 'IconBlocks' : return <IconBlocks key={i} data={component} />
    case 'ImpactTiles' : return  <ImpactTiles key={i} data={component} />
    case 'LeadershipBlock' : return <LeadershipBlock key={i} data={component} />
    case 'MediaCoverage' : return <MediaCoverage key={i} data={component} />
    case 'Openings' : return <Openings key={i} data={component} />
    case 'Pipeline' : return <Pipeline key={i} data={component} />
    case 'PatientStories' : return <PatientStories key={i} data={component} />
    case 'PrAndTwitter' : return <PRAndTwitter key={i} data={component} />
    case 'PromotedContent' : return <HybridPanels key={i} data={component} />
    case 'PromotedContentHalf' : return <PromotedContentHalf key={i} data={component} />
    case 'Publications' : return <Publications key={i} data={component} />
    case 'QuickStats' : return <QuickStats key={i} data={component} />
    case 'StackedIcons' : return <StackedIcons key={i} data={component} />
    case 'StaticHero' : return <StaticHero key={i} data={component} />
    case 'References' : return <References key={i} data={component} />
    case 'Text' : return <Text key={i} data={component} />
    case 'TextColumns' : return <TextColumns key={i} data={component} />
    case 'TriColumns' : return <TriColumns key={i} data={component} />
    case 'VideoHero' : return <VideoHero key={i} data={component} />
    case 'VideoSlider' : return <VideoSlider key={i} data={component} />
    case 'XenotransplantChart' : return <XenoChart key={i} />
    default : return <div key={i}> {compName} NOT SET</div>
  }

}

const Page = ({
  data: {
    wpPage, wpPage: { id, title, pageComponents, isFrontPage }
  }
}) => {
  const components = pageComponents.pageComponents

  return (
    <Layout isHome={isFrontPage} title={title}>
      <Seo post={wpPage} />
      {components && components.map((component, i) => ( switcheroo(component, i, isFrontPage) ))}
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    wpPage(id: { eq: $id }) {
      id
      title
      isFrontPage
      seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
              altText
              sourceUrl
              srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
              altText
              sourceUrl
              srcSet
          }
          canonical
          cornerstone
          schema {
              articleType
              pageType
              raw
          }
      }
      pageComponents {
        fieldGroupName
        pageComponents {
          ... on WpPage_Pagecomponents_PageComponents_Article {
            articleContent
            bottomSpacing
            padImageOnMobile
            caption
            columns
            fieldGroupName
            fullWidthHeading
            fullWidthImage
            heading
            noReorder
            lede
            tintedBackground
            topSpacing
            cta {
              url
              title
              target
            }
            desktopImage {
              sourceUrl
              title
            }
            mobileImage {
              sourceUrl
              title
            }
            video
          }
           ... on WpPage_Pagecomponents_PageComponents_Contact {
            fieldGroupName
            contactLocations {
              header
              address
            }
          }
          ... on WpPage_Pagecomponents_PageComponents_Hero {
            fieldGroupName
            panels {
              backgroundImage {
                sourceUrl
              }
              backgroundPosition {
                mobile
                desktop
              }
              heading
              subheading
            }
          }
          ... on WpPage_Pagecomponents_PageComponents_IconBlocks {
            bottomSpacing
            fieldGroupName
            tintedBackground
            topSpacing
            iconBlocks {
              heading
              description
              icon {
                title
                sourceUrl
              }
            }
          }
          ... on WpPage_Pagecomponents_PageComponents_ImpactTiles {
            fieldGroupName
            tintedBackground
            topSpacing
            bottomSpacing
            tiles {
              ... on WpPage_Pagecomponents_PageComponents_ImpactTiles_Tiles_People {
                bio
                fieldGroupName
                name
                quote
                title
                video
                poster {
                  title
                  sourceUrl
                }
              }
              ... on WpPage_Pagecomponents_PageComponents_ImpactTiles_Tiles_Quote {
                fieldGroupName
                quote
              }
            }
          }
          ... on WpPage_Pagecomponents_PageComponents_LeadershipBlock {
            bottomSpacing
            description
            fieldGroupName
            heading
            roster {
              person {
                ... on WpLeadership {
                  id
                  uri
                  title
                  leadership {
                    title
                    headshot {
                      title
                      sourceUrl
                    }
                  }
                }
              }
            }
            tintedBackground
            topSpacing
          }
          ... on WpPage_Pagecomponents_PageComponents_Btn {
            fieldGroupName
            buttonText
          }
          ... on WpPage_Pagecomponents_PageComponents_PatientStories {
            fieldGroupName
          }
          ... on WpPage_Pagecomponents_PageComponents_QuickStats {
            fieldGroupName
            tintedBackground
            topSpacing
            bottomSpacing
            stats {
              description
              fieldGroupName
              statNumber
              statSubject
            }
          }
          ... on WpPage_Pagecomponents_PageComponents_References {
            fieldGroupName
            topSpacing
            bottomSpacing
            hideTitle
            references
          }
          ... on WpPage_Pagecomponents_PageComponents_Text {
            fieldGroupName
            bottomSpacing
            content {
              ... on WpPage_Pagecomponents_PageComponents_Text_Content_Heading {
                fieldGroupName
                heading
                tagLevel
              }
            }
            tintedBackground
            topSpacing
          }
          ... on WpPage_Pagecomponents_PageComponents_PromotedContent {
            fieldGroupName
            story {
              cta {
                target
                title
                url
              }
              fieldGroupName
              quote
              quotee
              title
              type
              video
              image {
                altText
                title
                sourceUrl
              }
            }
          }
          ... on WpPage_Pagecomponents_PageComponents_StackedIcons {
            bottomSpacing
            fieldGroupName
            topSpacing
            tintedBackground
            heading
            icons {
              description
              icon {
                title
                sourceUrl
              }
            }
          }
          ... on WpPage_Pagecomponents_PageComponents_TriColumns {
            fieldGroupName
            block {
              heading
              text
            }
          }
          ... on WpPage_Pagecomponents_PageComponents_Publications {
            fieldGroupName
            publications {
              month
              year
              publicationData
              title
              url
              wherePublished
            }
          }
          ... on WpPage_Pagecomponents_PageComponents_Pipeline {
            fieldGroupName
            pipelineItems {
              leadProgram
              name
              progress
            }
          }
          ... on WpPage_Pagecomponents_PageComponents_PressReleases {
            fieldGroupName
            pressReleases {
              date
              fieldGroupName
              link
              title
            }
          }
          ... on WpPage_Pagecomponents_PageComponents_PrAndTwitter {
            fieldGroupName
            mediaInquiryDisclaimer
            tweets {
              tweet
            }
          }
          ... on WpPage_Pagecomponents_PageComponents_Openings {
            caption
            fieldGroupName
            text
            jobsApi
            introText
            figure {
              title
              sourceUrl
            }
          }
          ... on WpPage_Pagecomponents_PageComponents_MediaCoverage {
            bottomSpacing
            fieldGroupName
            tintedBackground
            topSpacing
            media {
              date
              link
              title
              image {
                title
                sourceUrl
              }
            }
          }
          ... on WpPage_Pagecomponents_PageComponents_TextColumns {
            bottomSpacing
            fieldGroupName
            tintedBackground
            topSpacing
            block {
              text
              heading
            }
            columns
          }
          ... on WpPage_Pagecomponents_PageComponents_StaticHero {
            bottomSpacing
            fieldGroupName
            heading
            text
            tintedBackground
            topSpacing
            cta {
              url
              target
              title
            }
            desktopImage {
              title
              sourceUrl
            }
            mobileImage {
              title
              sourceUrl
            }
            backgroundPosition {
              desktop
              mobile
            }
          }
          ... on WpPage_Pagecomponents_PageComponents_PromotedContentHalf {
            fieldGroupName
            intro
            story {
              cta {
                url
                title
                target
              }
              quote
              quotee
              title
              image {
                title
                sourceUrl
              }
            }
          }
          ... on WpPage_Pagecomponents_PageComponents_VideoHero {
            backupImage
            fieldGroupName
            videoUrl
          }
          ... on WpPage_Pagecomponents_PageComponents_VideoSlider {
            bottomSpacing
            fieldGroupName
            intro
            cta {
              url
              title
              target
            }
            tintedBackground
            topSpacing
            videoTiles {
              heading
              video
              backgroundImage {
                title
                sourceUrl
              }
            }
          }
          ... on WpPage_Pagecomponents_PageComponents_XenotransplantChart {
            fieldGroupName
          }
        }
      }
    }
  }
`

export default Page
