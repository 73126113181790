import React, { useState, useRef, useEffect } from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';
import ReactPlayer from 'react-player'

import './ImpactTiles.scss'



const QuoteTileM = ({ tile }) => {
  return (
    <div className="mobile-tile mobile-tile__quote">
      <h2 className="font-thin italic text-white">{tile.quote}</h2>
    </div>
  )
}





const PersonTileM = ({ tile, num }) => {
  const [showDetails, setShowDetails] = useState(false)
  const [showScrim, setShowScrim] = useState(false)
  const scrim = useRef(null)
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  const variants = {
    open: {
      opacity: 1,
      height: 'auto'
    },
    closed: {
      opacity: 0,
      height: 0,
    }
  }

  const handleShade = () => {
    const offset = scrim.current.getBoundingClientRect().top
    if (offset < 300 && offset > -100) {
      setShowScrim(true)
    } else {
      setShowScrim(false)
    }
  }


  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener('scroll', handleShade)
    }
    return () => window.removeEventListener('scroll', handleShade)
  }, [])


  return (
    <div className="mobile-tile mobile-tile__person" ref={ref}>
      <div className="video-block" onClick={() => setShowDetails(!showDetails)}>
        <ReactPlayer url={tile.video} muted loop playsinline playing={showDetails} width="106%" height="50vw" className="mobile-video-tile"/>
        <div className={`scrim ${showScrim ? 'peek' : ''} ${showDetails ? 'hide-scrim' : ''}`} ref={scrim}>
          <h2 className="text-white font-bold text-2xl mb-2">{tile.name}</h2>
          <p className="text-white">click to view</p>
        </div>
      </div>
      <div className="container">
        <motion.div
          animate={showDetails ? "open" : "closed"}
          variants={variants}
          transition={{duration: 0.5}}
        >
          <div className="info-block py-6">
            <h3 className="uppercase">{tile.name} | {tile.title} | {inView.toString()} </h3>
            <blockquote className="font-thin italic text-3xl leading-tight my-8">
              {tile.quote}
            </blockquote>
            <div dangerouslySetInnerHTML={{__html: tile.bio}} className="mb-6"/>
          </div>
        </motion.div>
      </div>
    </div>
  )
}









const ImpactTilesMobile = ({ tiles }) => {
  const person = 'page_Pagecomponents_PageComponents_ImpactTiles_Tiles_People'

  return (
    <div>
      {tiles.map( (tile, i) => (
        <div key={i} >
          {tile.fieldGroupName === person ? (
            <PersonTileM tile={tile} num={i} />
          ) : (
            <QuoteTileM tile={tile}/>
          )}
        </div>
      ))}
    </div>
  )
}

export default ImpactTilesMobile
