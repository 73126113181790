import React, { useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { StyledLink } from '../Buttons/Button'
import './PatientStories.scss'

import AOS from "aos";
import "aos/dist/aos.css";

const StorySnippet = ({story}) => {
  const { title, uri, storyContent } = story

  useEffect(() => {
    AOS.init({
      duration : 750,
      once: true
    });
  }, []);

  return (
    <article data-aos="fade-up" className="grid grid-cols-1 md:grid-cols-2 gap-8 my-16">
      <div>
        <img src={storyContent.teaserImage.sourceUrl} alt=""/>
      </div>
      <div>
        <header>
          <h1>{title}</h1>
          <h3 className="uppercase">{storyContent.teaserDescription} <span class="tallLine">&nbsp;</span> {storyContent.location}</h3>
        </header>
        <div dangerouslySetInnerHTML={{__html: storyContent.teaser}}></div>
        <footer className="buttons">
          <StyledLink to={uri}>READ FULL STORY</StyledLink>
          {storyContent.download && (
            <StyledLink to={storyContent.download.localFile.url} target="_blank" download>DOWNLOAD</StyledLink>
          )}

        </footer>
      </div>
    </article>
  )
}

const PatientStories = () => {
  // destructure props.data into user-friendly consts

  const data = useStaticQuery(graphql`
    query StoriesQuery {
      allWpStory {
        nodes {
          title
          id
          storyContent {
            teaser
            teaserDescription
            download {
              localFile {
                url
              }
            }
            teaserImage {
              title
              sourceUrl
              mediaDetails {
                height
                width
              }
            }
            location
          }
          uri
        }
      }
    }
  `)
  const stories = data.allWpStory.nodes


  return (
      <div className="container">
        {stories.map( (story) => (
          <StorySnippet story={story} key={story.id} />
        ))}
      </div>
  )
}

export default PatientStories
