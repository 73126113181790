import React from 'react'
import SpaceNColor from '../SpaceNColor/SpaceNColor'
import { motion } from 'framer-motion'

import './Pipeline.scss'

//hard coded
let pipelineData = [
  {
    "name": "HuCo™ Liver Perfusion",
    "leadProgram": true,
    "progress": "64"
  },
  {
    "name": "HuCo™ Kidney",
    "leadProgram": true,
    "progress": "55"
  },
  {
    "name": "HuCo™ Heart",
    "leadProgram": true,
    "progress": "35"
  },
]

const ProgressBar = ({leadProgram, progress}) => {

  const variants = {
    start: {
      width: 0
    },
    end: {
      width: `${progress}%`
    }
  }

  return (
    <motion.div
      className={`progress-bar ${leadProgram ? "lead" : ""}`}
      initial="start"
      animate="end"
      variants={variants}
      transition={{
        duration: 1,
        delay: 0.5
      }}
    />
  )
}

const Program = ({program}) => {


  return (
    <tr>
      <td className="border-r border-b border-text py-2 greydash">
        <div className="pl-6">
          {program.name}
        </div>
      </td>
      <td colSpan="4" className="border-b">
        <div className="w-full pb-holder">
          <ProgressBar
            leadProgram={program.leadProgram}
            progress={program.progress}
          />
        </div>
      </td>
    </tr>
  )
}

const Pipeline = ({ data }) => {
  // destructure props.data into user-friendly consts
  const {
    bottomSpacing,
    tintedBackground,
    topSpacing,
    pipelineItems
  } = data


  return (
    <SpaceNColor name='pipeline' tintedBackground={tintedBackground} bottomSpacing={bottomSpacing} topSpacing={topSpacing}>
      <div className="container">
        <div className="overflow-x-scroll table-wrap">
          <table className="w-full pipeline-table" borderspacing="0" cellPadding="0">
            <thead>
              <tr>
                <th className="td"></th>
                <th className="td dash">RESEARCH</th>
                <th className="td dash">PRECLINICAL</th>
                <th className="td dash">IND ENABLING</th>
                <th className="td">CLINICAL</th>
              </tr>
            </thead>
            <tbody>
              {pipelineData.map((program, i) => (
                <Program program={program} key={i} />
              ))}
            </tbody>
            <tfoot>
              {/* <tr>
                <td colSpan="4">
                  <div className="md:text-right">
                    <p className="legend">LEAD PROGRAMS</p>
                  </div>
                </td>
              </tr> */}
            </tfoot>
          </table>
        </div>
      </div>
    </SpaceNColor>
  )
}

export default Pipeline
