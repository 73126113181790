import React from 'react'
import ResponsiveFigure from '../ResponsiveFigure/ResponsiveFigure'
import ResponsiveVideo from '../ResponsiveVideo/ResponsiveVideo'
import { padding, bg, createLocalLink } from '../../utils'
import { StyledLink } from '../Buttons/Button'

import './Article.scss'

const ArticleHeading = ({heading, fullWidth}) => {
  return (
    <div>
      <h1>{heading}</h1>
    </div>
  )
}

const ArticleLede = ({lede}) => {
  return (
    <div className="article__lede">
      <div dangerouslySetInnerHTML={{ __html: lede}} />
    </div>
  )
}

const ArticleBody = ({content, cta}) => {
  return (
    <div className="article__body" >
      <div dangerouslySetInnerHTML={{__html: content}} />
      {cta && (
        <div className="mt-6">
          <StyledLink target={cta.target} arrow="right" to={createLocalLink(cta.url)}>{cta.title}</StyledLink>
        </div>
      )}
    </div>
  )
}


const Article = ({ data }) => {
  const {
    articleContent,
    bottomSpacing,
    caption,
    columns,
    desktopImage,
    video,
    heading,
    lede,
    mobileImage,
    tintedBackground,
    topSpacing,
    cta,
    padImageOnMobile,
    fullWidthHeading,
    fullWidthImage,
  } = data

  return (
    <div className={bg(tintedBackground)} style={padding(topSpacing, bottomSpacing)}>
      <div className="container jon">
        <article className={`
          article__grid
          ${columns}
          ${padImageOnMobile ? 'padded' : ''}
          ${fullWidthImage ? 'embiggen' : ''}`}
        >
          <div className={`article__heading ${fullWidthHeading ? 'article__heading--wide' : ''}`}>
            {heading && <h1 dangerouslySetInnerHTML={{ __html: heading}} /> }
          </div>
          {lede && <ArticleLede lede={lede} /> }
          {video && <ResponsiveVideo video={video} mobile={mobileImage} desktop={desktopImage} caption={caption} /> }
          {!video && desktopImage && <ResponsiveFigure mobile={mobileImage} desktop={desktopImage} caption={caption} /> }
          {articleContent && <ArticleBody content={articleContent} cta={cta} />}
        </article>
      </div>
    </div>
  )
}

export default Article
