import React, { useState, useRef, useEffect } from 'react'

import arrayShuffle from 'array-shuffle'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import ReactPlayer from 'react-player'

import './ImpactTiles.scss'


const PersonTile = ({data, handleClick}) => {
  const { name, video } = data
  const [isHovered, setIsHovered] = useState(false)
  const scrimClass = isHovered ? 'playing' : ''


  return (
    <div
      className="person-tile"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => handleClick()}
    >
      <BGVideo video={video} isHovered={isHovered} />
      <div className={`scrim ${scrimClass}`}>
        <h2 className="text-white font-bold text-2xl mb-2">{name}</h2>
        <p className="text-white">click to view</p>
      </div>
    </div>
  )
}



const FullVideo = ({data, handleClose}) => {
  const [isPlaying, setIsPlaying] = useState(true)


  return (
    <div className="full-video" >
      <ReactPlayer url={data.video} muted playing={isPlaying} loop />
      <footer className="absolute bottom-0 text-right w-full px-8 py-4">
        <button className="text-white ml-8" onClick={() => setIsPlaying(!isPlaying)}>pause ||</button>
        <button className="text-white ml-8" onClick={() => handleClose()}>close X</button>
      </footer>
    </div>
  )
}


const QuoteTile = ({data}) => {
  return (
    <div className="bg-indigo relative h-full flex justify-center flex-col align-middle p-10">
      <p className="text-white text-3xl italic font-light m-0 leading-9" style={{textIndent: '-8px'}}>{data.quote}</p>
    </div>
  )
}


const PersonDetails = ({data}) => {
  const [ elHeight, setElHeight ] = useState(0)
  const { bio, name, title, quote, } = data
  const animation = useAnimation()
  const theRef = useRef(null)

  const variants = {
    hidden: {
      opacity: 0,
      height: 0,
    },
    visible: {
      height: 'auto',
      opacity: 1,
    }
  }

  useEffect(() => {
    setElHeight(theRef.current.clientHeight)
    animation.start('visible')
  },[elHeight])


  return (
    <motion.div
      ref={theRef}
      variants={variants}
      animate={animation}
      initial='hidden'
      transition={{
        duration: 0.25,
        ease: 'easeOut'
      }}
    >
      <h2 className="my-8 font-bold text-2xl">{name} | {title}</h2>
      <div className="grid grid-cols-2 gap-8">
        <div className="text-4xl italic font-light leading-normal" style={{textIndent: '-10px'}}>
          {quote}
        </div>
        <div dangerouslySetInnerHTML={{__html: bio}} />
      </div>
    </motion.div>
  )
}

const BGVideo = ({video, isHovered}) => {
  return (
      <ReactPlayer
        className='react-player'
        url={video}
        muted
        loop
        width="auto"
        height="auto"
        playsinline
        playing={isHovered}
      />
  )
}




const ImpactTilesDesktop = ({ tiles }) => {

  const [currentTile, setCurrentTile] = useState(null)
  const [tilesShown, setTilesShown] = useState(false)

  const person = 'page_Pagecomponents_PageComponents_ImpactTiles_Tiles_People'
  const nineRandoms = arrayShuffle([1,2,3,4,5,6,7,8,9])
  const animation = useAnimation()
  const detailsAnimation = useAnimation()
  const pdRef = useRef(null)
  let insidePDRefHeight = 200
  const [ref, inView, entry] = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inView) {
      !tilesShown && animation.start("visible")
      setTilesShown(true)
    }
  }, [animation, inView]);

  const popInVariants = {
    hidden: {
      opacity: 0,
      y: entry,
    },
    visible: {
      y: 0,
      opacity: 1,
    }
  }

  const handleClick = (i) => {
    setCurrentTile(i + 1)
  }

  const handleCloseVideo = () => {
    setCurrentTile(null)
  }

  return (
    <div className="container">
      <div className="tile-grid grid grid-cols-3 relative">
        {tiles.map((tile, i) => (

          <motion.div
            key={i}
            variants={popInVariants}
            initial="hidden"
            animate={animation}
            ref={ref}
            transition={{
              delay: nineRandoms[i] / 5,
              duration: 1,
            }}
          >
            {tile.fieldGroupName === person ? (
              <PersonTile data={tile} handleClick={() => handleClick(i)} active={currentTile == i + 1} />
            ) : (
              <QuoteTile data={tile} />
            )}
          </motion.div>

        ))}
        {currentTile && <FullVideo data={tiles[currentTile - 1]} handleClose={handleCloseVideo} />}
      </div>
      <div className="tile-details">
        <div className="container">
          {currentTile && (
            <>
            <PersonDetails data={tiles[currentTile - 1]} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ImpactTilesDesktop
