import React, { useRef, useState, useEffect } from 'react'
import SpaceNColor from '../SpaceNColor/SpaceNColor'
import Glide, { Slide } from 'react-glidejs';
import { useWindowSize } from '../../utils'

import 'react-glidejs/dist/index.css';
import './MediaCoverage.scss'


const GlideSlide = ({media}) => {

  return (
    <div>
      {media.image && (
        <div className="slide-image" style={{backgroundImage: `url(${media.image.sourceUrl})`}} />
      )}

      <h3 className="uppercase">{media.date}</h3>
      <p>
        <a href={media.link} target="_blank">
          {media.title}
        </a>
      </p>
    </div>
  )
}

const MediaCoverage = ({ data }) => {
  const isBrowser = typeof window !== "undefined"
  const width = useWindowSize()
  const [slides, setSlides] = useState(2)
  const [winWidth, setWinWidth] = useState(null)
  const {
    bottomSpacing,
    tintedBackground,
    topSpacing,
    media
  } = data

  const gliderRef = useRef(null);
  const handlePrevious = () => gliderRef.current.go('<')
  const handleNext = () => gliderRef.current.go('>')

  const handleResize = () => {
    if(isBrowser) {
      let width = window.innerWidth
      setWinWidth(width)
      winWidth > 768
        ? setSlides(4)
        : setSlides(2)
    }
  }

  useEffect(() => {
    if(isBrowser) {
      handleResize()
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [winWidth, slides])



  return (
    <SpaceNColor name='media-coverage' tintedBackground={tintedBackground} bottomSpacing={bottomSpacing} topSpacing={topSpacing}>
      <div className="container">
        <div className="carousel-wrap">
          <Glide
            ref={gliderRef}
            throttle={0}
            type="carousel"
            perView={slides}
            startAt={0}
            gap={24}
            slideClassName="slider__frame"
          >
            {media.map( (media, i ) => (
              <GlideSlide key={i} media={media} />
            ))}
          </Glide>
          <button className="glide-btn left" onClick={handlePrevious}></button>
          <button className="glide-btn right" onClick={handleNext}></button>
        </div>
      </div>
    </SpaceNColor>
  )
}

export default MediaCoverage
