import React, { useEffect } from 'react'
import 'intersection-observer'
import './ResponsiveVideo.scss'
import AOS from "aos";
import "aos/dist/aos.css";
import InView from 'react-intersection-observer';

const ResponsiveVideo = (props) => {
  const {mobile, desktop, video, caption} = props
  
  useEffect(() => {
    AOS.init({
      duration : 750,
      once: true,
    });
  }, []);

  const playVideo = (inView, entry) => {
      let video = entry.target.children[0];
      video.playbackRate = 1.35;
      // console.log(inView);
      if (!inView) {
          video.pause();
      } else {
          video.play();
      }
  }

  return (
    <figure className="responsive-video">
        <InView triggerOnce={true} onChange={(inView, entry) => playVideo(inView, entry)} dangerouslySetInnerHTML={{ __html: `
                <video autoplay preload muted playsinline>
                    <source src="${video}" type="video/mp4" />    
                    <picture>
                        <source data-aos="fade-up" srcSet="${desktop.sourceUrl}" />
                    </picture>
                </video>`
            }}/>
        <picture class="mobile">
            <img data-aos="fade-up" src={mobile.sourceUrl} alt={desktop.title} />
        </picture>
     {caption &&
        <figcaption
          className="text-mediumgray text-sm italic border-t border-mediumgray pt-4 mt-4"
          dangerouslySetInnerHTML={{__html: caption}}
        />
      }
    </figure>
  )
}

export default ResponsiveVideo
