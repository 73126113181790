import React, { useState, useEffect } from 'react'
import { motion } from "framer-motion";
import { useWindowSize } from '../../utils'
import PromoPanel from '../PromoPanel/PromoPanel'
import PromoPanelMobile from '../PromoPanelMobile/PromoPanelMobile'
import "./PromotedContentHalf.scss"

const DescriptionPanel = ({isStoryOpen, intro}) => {
  const [transform, setTransform] = useState(0)
  useEffect(() => {
    isStoryOpen
      ? setTransform(-50)
      : setTransform(0)
  }, [isStoryOpen])
  return (
    <div className="desc-panel" style={{transform: `translateX(${transform}vw)`}}>
      <div className="col-start-2">
          <div dangerouslySetInnerHTML={{__html: intro}}></div>
      </div>
    </div>
  )
}

const HalfPromoNarrow = ({stories, intro}) => {
  return (
    <div>
      <div className="py-8 bg-lightblue">
        <div className="container">
          <div dangerouslySetInnerHTML={{__html: intro}} />
        </div>
      </div>
      {stories.map((story, i) => (
        <PromoPanelMobile key={i} story={story} />
      ))}
    </div>
  )
}

const HalfPromoWide = ({stories, intro}) => {
  const [ activePanel, setActivePanel ] = useState(null)
  const [ isOpen, setIsOpen ] = useState(false)
  const [ translateX, setTranslateX ] = useState(0)
  const viewportCSS = isOpen ? 'open' : ''

  const handleClick = (i) => {
    setIsOpen(!isOpen)
    activePanel ? setActivePanel(null) : setActivePanel(i)
    setTranslateX(`-${(i * 25) + 50}vw`)
  }

  const handleClose = () => {
    setIsOpen(false)
    setActivePanel(null)
    setTranslateX(0)
  }

  return (
    <div className={`promoted-content halfsies ${viewportCSS}`} style={{transform: `translateX(${translateX})`}}>
      <DescriptionPanel
        isStoryOpen={isOpen}
        intro={intro}
      />
      {stories.map( (story, i) => (
        <PromoPanel
          story={story}
          key={i}
          isOpen={activePanel === i}
          onBtnClick={() => handleClick(i)}
          handleClose={handleClose}
        />
      ))}
    </div>
  )
}

const PromotedContentHalf = ({ data }) => {
  const {
    story: stories,
    intro,
  } = data
  const width = useWindowSize()

  return (
      <div className="pm-wrap">
        {width >= 768 ? (
          <HalfPromoWide stories={stories} intro={intro} />
          ) : (
          <HalfPromoNarrow stories={stories} intro={intro} />
        )}
      </div>
  )
}

export default PromotedContentHalf
