import React, { useState, useRef, useEffect } from 'react'
import { StyledLink } from '../Buttons/Button'
import { ReactComponent as Close} from '../../images/close-icon-white.svg'
import ReactPlayer from 'react-player'


const MobileVideo = ({tile, handleClose}) => {



  return (
      <div className="hvpm-video flex justify-center flex-col absolute h-full w-full top-0 bg-indigo">
        <header className="flex justify-end p-6" style={{marginTop: "-40px"}}>
          <Close onClick={handleClose} />
        </header>
        <ReactPlayer
          url={tile.video}
          controls
          width="100%"
        />
      </div>
  )
}

const HybridVideoPanelMobile = ({story: tile}) => {
  const blue = 'linear-gradient(rgba(35, 53, 132, 0.4), rgba(35, 53, 132, 0.4))'
  const [active, setActive] = useState(false)

  const handleClick = () => {
    setActive(!active)
  }

   return (
    <div className='hvpm container relative'>
      <div
        className="front"
        style={{backgroundImage: `${blue}, url(${tile.image.sourceUrl})`}}
      >
        <div>
          <h2 className="text-white">{tile.title}</h2>
          <button className="text-white fancy" onClick={handleClick}>PLAY</button>
        </div>
      </div>
      {active && (
        <MobileVideo tile={tile} handleClose={handleClick} />
      )}
    </div>
  )
}

export default HybridVideoPanelMobile
